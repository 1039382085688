import { graphql, navigate, useStaticQuery } from "gatsby";
import GoogleMapReact from "google-map-react";
import React from "react";
import Card from "../components/controls/card";
import Heading from "../components/typography/heading";
import Head from "../components/layout/head";
import Hero from "../components/layout/hero";
import Root from "../components/layout/root";
import Section from "../components/layout/section";
import Edge from "../data/edge";
import Link from "../components/controls/link";
import Vehicle from "../data/vehicle";

const Index = () => {
  const query: any = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          map {
            lat
            lon
            defaultZoom
          }
        }
      }
      latestVehicles: allInventoryJson(
        sort: { fields: [lastmodifstamp], order: DESC }
        limit: 4
      ) {
        edges {
          node {
            id
            brand
            kms
            model
            price
            transmission
            year
            images
          }
        }
      }
    }
  `);

  const { lat, lon, defaultZoom } = query.site.siteMetadata.map;

  const latestVehicles: Vehicle[] = query.latestVehicles.edges.map(
    (x: Edge<Vehicle>) => {
      const car: Vehicle = x.node as Vehicle;
      const images: string[] = [];

      for (let i = 0; i < x.node.images.length; i++) {
        const imageNode = x.node.images[i];
        if (imageNode) {
          images.push(imageNode);
        }
      }

      car.images = images;
      return car;
    }
  );

  /*Map marker with pulsing animation */
  const Marker = (props: any) => {
    return (
      <>
        <Link
          to={
            "https://www.google.com/maps/dir/?api=1&" +
            "origin=My+Location&destination=41.5337583,-8.4976497&travelmode=driving"
          }
          internal={false}
          openNewTab={true}
        >
          <div className="pin" />
          <div className="pulse" />
        </Link>
      </>
    );
  };

  return (
    <Root>
      <Head title="Home" />
      <Hero />
      <Section className="bg-tertiary-700 py-10 lg:py-15">
        <Heading className="text-center">Últimas Entradas</Heading>
        <div className="container flex flex-col lg:flex-row">
          {latestVehicles.map((vehicle, index) => {
            return (
              <Card
                className={`flex-1 mx-10 ${index == 0 ? "lg:ml-4" : "mt-5"} ${
                  index == latestVehicles.length - 1 ? "lg:mr-4" : "mb-5"
                } lg:mr-2 lg:ml-2 lg:mb-0 lg:mt-0`}
                key={index}
                make={vehicle.brand}
                model={vehicle.model}
                year={parseInt(vehicle.year)}
                price={parseInt(vehicle.price)}
                mileage={parseInt(vehicle.kms)}
                transmission={
                  vehicle.transmission === "manual" ? "manual" : "auto"
                }
                onClick={() => {
                  navigate(`/vehicles/${vehicle.id}`);
                }}
                image={vehicle.images[0]}
              />
            );
          })}
        </div>
      </Section>
      {/* <Selector /> */}
      <Section
        container={true}
        className="bg-primary-100 text-tertiary-700 py-10 lg:py-32"
      >
        <div className="flex flex-col">
          <h1 className="uppercase text-center text-4xl pb-12">
            Bem-vindos ao <span className="font-bold">Stand Carvalho</span>
          </h1>
          <div className="center m-auto">
            <hr className="w-48 border-highlight-500 border-2" />
          </div>
          <p
            className="text-lg text-center font-medium leading-relaxed pt-10"
            style={{
              backgroundImage: "url(data/site-images/logo.png)",
              backgroundSize: "100% 100%",
              display: "inline-block",
              overflow: "hidden"
            }}
          >
            No mercado à mais de 30 anos, mantem-se o{" "}
            <span className="font-semibold">líder do mercado circundante</span>{" "}
            — sendo o enorme volume de vendas a prova da preferência dos seus
            clientes. Não deixe de visitar para conferir o porquê de tanta gente
            optar por comprar no Stand Carvalho.
          </p>
          <p className="text-lg text-center font-medium leading-relaxed pt-10">
            Visite-nos também no{" "}
            <Link
              to="https://standcarvalho.standvirtual.com/"
              internal={false}
              openNewTab={true}
              className="text-secondary-500 mx-1"
            >
              Stand Virtual
            </Link>
            para mais informações.
          </p>
        </div>
      </Section>
      <Section container={false}>
        <div className="w-full" style={{ height: 375 }}>
          <GoogleMapReact
            /*onGoogleApiLoaded={({ map, maps }) =>
                            renderMarkers(map, maps)
                        }*/
            bootstrapURLKeys={{
              key: "AIzaSyCeIofNRbratxDalQSh5aFL3kXPzrZ_HjI"
            }}
            defaultCenter={{ lat: lat, lng: lon }}
            defaultZoom={defaultZoom}
          >
            <Marker lat={lat} lng={lon} />
          </GoogleMapReact>
        </div>
      </Section>
    </Root>
  );
};

export default Index;
